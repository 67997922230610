import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { storeValue, hideHeaderSurveyCalloutKey } from '../util/storage-utils';
import SurveyMonkeyModal from './SurveyMonkeyModal';
import styles from '../banners/HeaderBanner.module.css';

import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

// Should only be shown to customers with at least 1 completed order
const SurveyCalloutInHeader = () => {

  const [hideBanner, setHideBanner] = React.useState();
  const closeBanner = () => {
    setHideBanner(true);
    trackEvent('survey_hdr_callout_hide');
    storeValue(hideHeaderSurveyCalloutKey, true);
  }

  return ( hideBanner
    ? null
    : <div className={styles.addressOverlay}>
        <SurveyMonkeyModal
          ctaText='For 40% OFF'
          linkText='Take our 3 Minute Survey!' />
        <span className={styles.overlayClose} onClick={closeBanner}>
          <HighlightOffRoundedIcon style={{fontSize:'25px'}} />
        </span>
      </div>
  );
};

export default SurveyCalloutInHeader;
