import * as React from 'react';
import { Modal } from '@material-ui/core';
import CloseModalIcon from '../common/CloseModalIcon';
import PropTypes from 'prop-types';

import styles from './MuiModal.module.css';

const MuiModal = ({
  open=false,
  closeFn,
  className='',
  closeText,
  children,
}) => (
  <Modal
    open={open}
    BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,.35)'}}}>
    <div className={`${styles.muiModal} ${className}`}>
      <CloseModalIcon classes={styles} height={16} closeFn={closeFn} />
      {children}
      { closeText &&
        <div className={styles.closeLabel} onClick={closeFn}>
          {closeText}
        </div>
      }
    </div>
  </Modal>
)

MuiModal.propTyes = {
  open: PropTypes.bool,
  closeFn: PropTypes.func.isRequired,
  className: PropTypes.string,
  closeText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired,
}

export default MuiModal;
