import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import useSessionLength from '../analytics/useSessionLength';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { ModalContext, ModalType } from '../modal/ModalProvider';

import Button from '../styleguide/Button';

/**
 * If the user is seeing the "Sold Out" message and their product data is old,
 * prompt them to refresh the app (if their cart is empty)
 */
const RefreshAppPrompt = () => {

  // If the session start is >= 1 hour ago, prompt an app refresh
  const REFRESH_THRESHOLD_HOURS = 1;

  const { user } = React.useContext(UserContext);
  const { isEmptyCart } = React.useContext(CartItemsContext);
  const { displayModal } = React.useContext(ModalContext);

  const { sessionHours, sessionTimeDisplay } = useSessionLength();
  const [refreshMessage, setRefreshMessage] = React.useState();

  React.useEffect(() => {
    // We don't want logged-out users to lose their cart items by refreshing
    // Logged-in user's cart are auto-restored on refresh
    if ((isEmptyCart || !user?.isAnonymous) && sessionHours >= REFRESH_THRESHOLD_HOURS) {
      trackEvent('refresh_app_prompt_view');
      setRefreshMessage(sessionTimeDisplay);
    }
  }, [isEmptyCart, user, sessionHours, sessionTimeDisplay])

  return (
    <div>
      { refreshMessage
        ? <div>
            Hey, your product selection is <b>{refreshMessage} old</b>, let's get you all the latest products!
            <Button isCentered text="Refresh Products" handleClick={() => window.location.reload()} />
          </div>
        : <Button isCentered text="Continue Shopping" handleClick={() => displayModal(ModalType.NONE)} />
      }
    </div>
  )
}

export default  RefreshAppPrompt;
