import { useContext, useEffect, useState } from "react";
import { CartItemsContext } from "../checkout/CartItemsProvider";

const buildProductMetaData = (cartItems=[], cart_subtotal_cents) => {
  const product_ids = [];
  const product_skus = [];
  const categories = new Set();
  const subcategories = new Set();
  cartItems.forEach(item => {
    product_ids.push(item.id);
    product_skus.push(item.dutchie_sku);
    categories.add(item.display_info.category.name);
    // Handle missing data in dev...
    if (item.display_info.sub_category) {
      subcategories.add(item.display_info.sub_category.name);
    } else {
      subcategories.add('no data');
    }
  })
  return {
    cart_subtotal_cents,
    product_ids,
    product_skus,
    product_categories: Array.from(categories),
    product_subcategories: Array.from(subcategories),
  }
}

const useProductMetaData = () => {

  // Note: cartItems is currently a Ref, it's itemTotal that triggers the recalc
  const { cartItems, itemTotal:cart_subtotal_cents } = useContext(CartItemsContext);
  const [metaData, setMetaData] = useState();

  useEffect(() => {
    setMetaData(buildProductMetaData(cartItems, cart_subtotal_cents));
  }, [cartItems, cart_subtotal_cents])

  return {
    metaData,
  }
}

export default useProductMetaData;
