/**
 * These used to display with the product carousels
 * They don't currently display because the title keys are now: 'Popular Flower'
 * 'Order Again' is still in use
 */
export const categoryTaglines = {
  Accessories : "Select accessories for use with cannabis products and everyday life.",
  Concentrates : "Highly potent cannabis extracts \u2014favored by advanced customers.",
  Edibles : "Enjoy an elevated experience with THC-enhanced drinks, gummies, chocolates, and more.",
  FlowerX : "The best bud in Boston. Roll it up or pack it into your preferred smoking device.",
  'Pre-Rolls': "Relish your bud \u2014packed to perfection and ready for you to enjoy.",
  Tinctures : "Consume highly concentrated herbal extracts that help with reducing anxiety and relieving pain.",
  Topicals : "Quality topicals curated to provide your skin with a soothing experience.",
  Vaporizers: "Handpicked vapes chosen to provide you with a premium cannabis experience.",
  "Order Again": "Products from your recent orders, in stock and ready for delivery."
};
