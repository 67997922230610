import * as React from 'react';

import useBrandPartnerData from './useBrandPartnerData';
import Header, { HeaderSize } from '../styleguide/Header';
import BrandHero from './BrandHero';
import PartnerDetails from './PartnerDetails';
import DeliveryServiceDetails from './DeliveryServiceDetails';
import { PropTypes } from 'prop-types';

import styles from './BrandHeader.module.css';

const BrandHeader = ({
  brand
}) => {

  const { data:partnerData={}} = useBrandPartnerData(brand);
  const { name, display_name } = partnerData;

  return ( brand && partnerData.name
    ? <div style={{position:'relative'}}>
        <BrandHero brandName={brand} />
        <div className={styles.cobrandGrid}>
          <div className={styles.cobrandItem}>
            <Header size={HeaderSize.Large}
                    text={display_name || name}
                    withStyle={{ marginBottom:4 }}  />
            <PartnerDetails brandName={brand} />
          </div>
          <div className={styles.cobrandItem}>
            <DeliveryServiceDetails />
          </div>
        </div>
      </div>
    : null
  )
}

BrandHeader.propTypes = {
  brand: PropTypes.string, /* via router */
}

export default BrandHeader;
