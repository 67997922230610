import * as React from "react";

import { devLog } from "../util/util";
import { uploadImage } from './registration-utils';
import ImageUploading from 'react-images-uploading';
import ProgressCircle from './ProgressCircle';
import BasicButton from "../styleguide/BasicButton";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorOutlineRounded from "@material-ui/icons/ErrorOutlineRounded";
import PropTypes from 'prop-types';

import styles from './PhotoIdInline.module.css';

// Firebase image upload max
const MAX_IMAGE_KB = 4000000;
const MAX_IMAGE_KB_LABEL = '4MB';

/**
 *  Upload/Update a license image to Firebase
 */
const LicenseImageUploader = ({
  email,
  placeholderImage,
  primaryPhotoId,
  setPhotoIdImage
}) => {

  // Not wired up with Firebase upload
  const [photoIdErr, setPhotoIdErr] = React.useState();
  // Temporarily store images in a directory that's a base64 encoding of their email
  const encodedEmail = encodeURIComponent(window.btoa(email.trim()));
  // Firebase upload progress
  const [uploadPct, setUploadPct] = React.useState(-1);

  // Callback for Firebase upload
  const onUploadComplete = (imageInfo) => {
    devLog('image upload complete');
    if (imageInfo.error) {
      setPhotoIdErr(imageInfo.error);
    } else if (imageInfo.path && imageInfo.url) {
      setPhotoIdImage(imageInfo);
    }
  };

  const onUploadProgressChange = (pctComplete) => {
    devLog(`upload pct: ${parseInt(pctComplete, 10)}`);
    setUploadPct(parseInt(pctComplete, 10));
  }

  // After image selected
  const onSelectImage = imageList => {
    // image array of 1
    const firstImage = imageList[0].file;
    uploadImage(firstImage, encodedEmail, onUploadComplete, onUploadProgressChange);
  };

  return (
    <div className={styles.imageItem}>
      <ImageUploading
        onChange={onSelectImage}
        /* isCaseSensitive={true} version 3.1.0 */
        acceptType={['jpg', 'jpeg', 'gif', 'png',
                     'JPG', 'JPEG', 'GIF', 'PNG',
                     'Jpg', 'Jpeg', 'Gif', 'Png']}
        /* For the image update to work we need MAX=2, oddly. */
        maxNumber={2}>
        {({ imageList, onImageUpload }) => (
          <>
            {/* display placeholder image or previously uploaded Id image from provider */}
            { imageList.length === 0
              ? <>
                  <img className={styles.placeholder} src={
                    primaryPhotoId
                      ? primaryPhotoId.url
                      : placeholderImage
                    }
                    alt="" onClick={onImageUpload} />
                  <BasicButton
                    withClass={styles.uploadButton}
                    text={primaryPhotoId ? "Select a Different Image" : "Select ID Image"}
                    handleClick={onImageUpload} />
                </>
              /* display selected image */
              : imageList.map((image, idx) => {
                return (
                  <div key={`img${idx}`}>
                    <img className={styles.licenseImage} src={image.dataURL} alt="license" />
                    <BasicButton
                      withClass={styles.uploadButton}
                      text="Select a Different Image"
                      handleClick={image.onUpdate} />
                    <div className={styles.progressWrap}>
                      { photoIdErr
                        ? <div className={styles.updateMsg}>{photoIdErr}</div>
                        : uploadPct > 0
                          ? uploadPct === 100
                            ? image?.file?.size && image.file.size < MAX_IMAGE_KB
                              ? <div><CheckCircleRoundedIcon className={styles.sizeIcon} /></div>
                              : <div><ErrorOutlineRounded className={`${styles.sizeIcon} ${styles.warningIcon}`} /></div>
                            : <ProgressCircle percentageInt={uploadPct} />
                          : null
                      }
                    </div>
                    <div>
                      { image?.file?.size && image.file.size < MAX_IMAGE_KB
                        ? <span className={styles.sizeMsg}>
                            File size OK: {`${Math.floor((image?.file?.size || 0)/1000)}k`}
                          </span>
                        : <span className={`${styles.sizeMsg} ${styles.warningMsg}`}>
                            {`Your ID file is too large (over ${MAX_IMAGE_KB_LABEL})`}:
                            {` ${Math.floor((image?.file?.size || 0)/1000)}k`}
                            {' '}Please select a smaller image.
                          </span>
                      }
                    </div>
                  </div>
                )})
            }
          </>
        )}
      </ImageUploading>
    </div>
  );
};

LicenseImageUploader.propTypes = {
  email: PropTypes.string.isRequired,
  placeholderImage: PropTypes.string.isRequired,
  primaryPhotoId: PropTypes.shape({
    url: PropTypes.string,
  }),
  setPhotoIdImage: PropTypes.func.isRequired
};

export default LicenseImageUploader;
