import * as React from 'react';

import { trackEvent, trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide sub-category BRAND filters for both Desktop and Mobile layouts
 */
const BundleFilter = ({
  startOpen=false,
  inModal=false
}) => {

  // Current filters context
  const { bundleId:selectedBundleId, subCategoryBundles=[], setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'All products' option
  const subCategoryBundlesWithAll = [SHOW_ALL, ...subCategoryBundles];

  // Toggle the display
  const [showBundles, setShowBundles] = React.useState(startOpen);

  const toggleShowBundles = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showBundles);
    }
    setShowBundles(!showBundles);
  }

  const bundleClick = (bundleId) => {
    trackEvent(`filter_mixmatch_${trackingString(bundleId)}_click`);
    setFilters({bundleId});
  };

  const labelRef = React.useRef();

  const addlClass = inModal ? styles.inModal : '';

  return (subCategoryBundlesWithAll.length > 0
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}>
        <div ref={labelRef} data-show-content={showBundles} className={styles.sectionHdr} onClick={toggleShowBundles}>
          Mix&thinsp;&&thinsp;Match <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showBundles &&
          <ul className={styles.filterLinks}>
            { subCategoryBundlesWithAll.map(bundle => {
              const selectedClass = bundle.bundleId === selectedBundleId
                ? styles.selected
                : '';
              const label = bundle === SHOW_ALL
                          ? `All Products`
                          : bundle.teaserText;
              return <li key={bundle.bundleId}
                         className={`${styles.filterLink} ${selectedClass}`}
                         onClick={() => {bundleClick(bundle.bundleId)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }
      </div>
    : null
  );
}

export default BundleFilter;
