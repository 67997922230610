import * as React from 'react';

import useProductPricing from './useProductPricing';
import PropTypes from 'prop-types';

import styles from './DisplayPrice.module.css';

/**
 * Handle display of sale price next to original price and
 * include BUNDLE/BULK pricing calcs bazsed on carted items
 */
const DisplayCartedPrice = ({
  product,
  quantity=1,
}) => {

  // true = include carted items in bundled price calc
  const { priceDisplay, salePriceDisplay } = useProductPricing(product, quantity, true);

  return (
    <span>
      <span className={salePriceDisplay ? styles.onSale : ''}>
        <span className={styles.amount}>
          {priceDisplay}
        </span>
      </span>
      { salePriceDisplay &&
        <span className={styles.salePrice}>
          {salePriceDisplay}
        </span>
      }
    </span>
  )
}

DisplayCartedPrice.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number,
}

export default  DisplayCartedPrice;
