import { auth } from "../../firebase";
import { surveyCompletionEndpoint } from "../../constants/api-endpoints";
import { getStoredValue, currentSurveyCompletedKey } from '../util/storage-utils';

export const triggerThankYouEmail = (customer_id, survey_id, onSuccess, onFailure, controller) => {
  const body = JSON.stringify({
    customer_id,
    survey_id
  });

  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(surveyCompletionEndpoint, {
        method: 'POST',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json'
        },
        body,
        signal: controller.signal
      }).then(response => {
        if(response.status === 201) {
          onSuccess();
        } else {
          response.json()
            .then(data => {
              const { error='Email request failed, please contact support' } = data;
              onFailure({message:error});
            })
        }
      }).catch(error => {
        onFailure(error);
      });
    });
  }
};

// Check for storage value set on current survey completion
export const hasStoredCurrentSurveyKey = () => {
  return getStoredValue(currentSurveyCompletedKey);
}
