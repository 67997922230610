import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import useProductsBySubCategory from './useProductsBySubCategory';
import ProductCarousel from './ProductCarousel';
import FlowerSortedByTHC from './FlowerSortedByTHC';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

const SubCategoryCarousels = ({
  categoryName=''
}) => {

  const { productsBySubCategory } = useProductsBySubCategory(categoryName);

  // We don't currently handle invalid category name/empty map here
  return ( productsBySubCategory
    ? <>
        {[...productsBySubCategory.keys()].map(subCategoryName => {
          const subCatProducts = productsBySubCategory.get(subCategoryName);
          return <ProductCarousel
                   key={subCategoryName}
                   /* Handle "See All All Other Vaporizers" etc. */
                   carouselTitle={subCategoryName.replace('All ', '')}
                   categoryCount={subCatProducts.length}
                   carouselProducts={subCatProducts}
                   withMoreCard
                   seeAllClick={() => {
                    navigateTop(`/products/${categoryName}/${subCategoryName}`);
                   }} />
        })}
        { categoryName === 'Flower'
          ? <FlowerSortedByTHC />
          : null
        }
      </>
    : <Spinner />
  )
}

SubCategoryCarousels.propTypes = {
  categoryName: PropTypes.string
};

export default SubCategoryCarousels;
