import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { PropTypes } from 'prop-types';
import styles from './GenericHeader.module.css';

/**
 * Generic gradient page header with title
 */
const GenericHeader = ({
  headerTitle,
  returnToPath="/"
}) => {

  return ( headerTitle
    ? <div className={styles.outerWrap}>
        <div className={styles.gradientWrap}>
          <div className={styles.backLink} onClick={() => navigateTop(returnToPath)}>
            <img alt="" className={styles.backIcon} src="/img/backArrowGlow.png" />
            Back
          </div>
          <div className={styles.grid}>
            <div className={styles.title}>{headerTitle}</div>
          </div>
        </div>
      </div>
    : null
  )
}

GenericHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  returnToPath: PropTypes.string,
}

export default GenericHeader;
