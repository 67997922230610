import { useState, useEffect } from 'react';

/**
 * const options = {
 *   root: document.querySelector("#scrollArea"),
 *   // Use negative margin rather than absolute positioning of target
 *   rootMargin: "-100px 0px 0px 0px",
 *   threshold: 1.0,
 * };
 */

// A replacement for observing scroll
const useIntersectionObserver = (targetRef, options) => {

  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetElem = targetRef.current;

  useEffect(() => {
    let observer;
    if ("IntersectionObserver" in window) {
      observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options);

      if (targetElem) {
        observer.observe(targetElem);
      }
    };

    return () => {
      if (observer && targetElem) {
        observer?.unobserve(targetElem);
      }
    }
  }, [options, targetElem]);

  return {
    isIntersecting
  };
};

export default useIntersectionObserver;
