import * as React from 'react';

import BundleFilter from './BundleFilter';
import FilterWithinCategory from './FilterWithinCategory';
import MoodFilter from './MoodFilter';
import WeightFilter from './WeightFilter';
import BrandFilter from './BrandFilter';
import TypeFilter from './TypeFilter';
import FlowerTypeFilter from './FlowerTypeFilter';
import PricingFilter from './PricingFilter';
import SortFilter from './SortFilter';

import PropTypes from 'prop-types';

/**
 * This component provides a set of product filters ( weight, brand etc. )
 */
const ProductFilters = ({
  onSubCategoryClick,
  startOpen=false,
  inModal=false
}) => {

  return (
    <>
      {/* will collapse if empty */}
      <FilterWithinCategory onSubCategoryClick={onSubCategoryClick} />
      <MoodFilter startOpen={startOpen} inModal={inModal} />
      <TypeFilter startOpen={startOpen} inModal={inModal} />
      <FlowerTypeFilter startOpen={startOpen} inModal={inModal} />
      <PricingFilter startOpen={startOpen} inModal={inModal} />
      <BundleFilter startOpen={startOpen} inModal={inModal} />
      <SortFilter startOpen={startOpen} inModal={inModal} />
      <WeightFilter  startOpen={startOpen} inModal={inModal} />
      <BrandFilter startOpen={startOpen} inModal={inModal} />
    </>
  );
}

ProductFilters.propTypes = {
  onSubCategoryClick: PropTypes.func,
  startOpen: PropTypes.bool,
  inModal:  PropTypes.bool
}

export default ProductFilters;
