import React from 'react';

import useBrandPartnerData from './useBrandPartnerData';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tagLine: {
    fontSize:'1.125rem',
    margin: '0 0 .5rem',
  }
}));

const PartnerDetails = ({brandName}) => {

  const classes = useStyles();
  const { data } = useBrandPartnerData(brandName);

  return (data?.tagline
    ? <div className={classes.tagLine}>
        { data.tagline }
      </div>
    : null
  );
}

export default PartnerDetails;
