import * as React from 'react';

import { useAnalyticsStore } from '../../App';

const useSessionLength = () => {

  // Page load session start
  const { sessionStartMS } = useAnalyticsStore();

  const [sessionTime, setSessionTime] = React.useState();
  const [sessionTimeDisplay, setSessionTimeDisplay] = React.useState();

  // Calc session length (from page load)
  const calcSessionLength = React.useCallback(() => {
    const ms = new Date().getTime() - sessionStartMS;
    const seconds = Math.floor(ms/1000);
    const hours = Math.floor(seconds / 3600);
    // remainder minutes
    const minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes, ms };
  }, [sessionStartMS]);

  React.useEffect(() => {
    if ( typeof sessionStartMS === 'number') {
      const { hours, minutes, ms } = calcSessionLength(sessionStartMS);
      setSessionTime({hours, minutes, ms});
      setSessionTimeDisplay(`${hours} hour(s) and ${minutes} minutes`);
    }
  }, [sessionStartMS, calcSessionLength]);

  return {
    sessionHours: sessionTime?.hours,
    sessionMinuteRemainder: sessionTime?.minutes,
    sessionMS: sessionTime?.ms,
    sessionTimeDisplay,
  };
}

export default useSessionLength;
