import * as React from 'react';

import { devLog } from '../util/util';
import { storeValue, currentSurveyCompletedKey } from '../util/storage-utils';
import { triggerThankYouEmail } from './survey-util';
import { UserContext } from '../providers/UserProvider';
import { trackEvent } from '../analytics/tracking';
import Spinner from '../common/Spinner';
import styles from './SurveyMonkey.module.css';

// The current Q1 2025 survey
export const SURVEY_TRACKING_LABEL = 'q1_25_survey'; // Unique to each survey!
export const SURVEY_STATIC_PAGE = '/q1_25_survey.html'; // Static page for iframe

const SurveyEmbed = ({
  onComplete, // close modal etc.
}) => {

  const { userId } = React.useContext(UserContext);
  // Send the Thank You email via Postmark with promo code
  const triggerEmail = React.useCallback(() => {
    triggerThankYouEmail(
      userId,
      SURVEY_TRACKING_LABEL,
      () => trackEvent(`${SURVEY_TRACKING_LABEL}_email_success`),
      (/* error */) => trackEvent(`${SURVEY_TRACKING_LABEL}_email_error`),
      new AbortController()
    )
  }, [userId])

  React.useEffect(() => {
    const handleSurveyMessage = (event) => {
      // Listen only to same-origin messages
      if (event.origin !== window.location.origin) return;
      const { data  } = event; //JSON.parse(event.data || '{}');
      const { eventName } = data || {};

      // We're provided with "survey loaded" and "thank you page loaded" messages
      if (eventName === 'sm:survey_page_loaded') {
        trackEvent(`${SURVEY_TRACKING_LABEL}_loaded`);
        setLoading(false);
      } else if (eventName === 'sm:thank_you_page_loaded') {
        trackEvent(`${SURVEY_TRACKING_LABEL}_completed`);
        triggerEmail();
        setCompleted(true);
        storeValue(currentSurveyCompletedKey, true); // Suppress survey banners
      }
      eventName && devLog(eventName);
    }
    window.addEventListener("message", handleSurveyMessage);
    return () => window.removeEventListener("message", handleSurveyMessage);
  }, [triggerEmail]);

  const [loading, setLoading] = React.useState(true);
  const [completed, setCompleted] = React.useState();

  return (
    <div className={styles.surveyWrapper}>
      { loading &&
        <div className={styles.spinnerWrap}>
          <Spinner isInline size={48} />
        </div>
      }
      <iframe
        title="Zyp Run Quarterly Survey"
        className={styles.surveyIframe}
        width="100%"
        src={SURVEY_STATIC_PAGE} />
      { completed && onComplete &&
        <div className={styles.completedMsg}>
          <div className={styles.completedCloseLink} onClick={onComplete}>Close</div>
        </div>
      }
    </div>
  );
};

export default SurveyEmbed;
