import * as React from 'react';

import { PropTypes } from 'prop-types';

import styles from './PartnerHeader.module.css';

/**
 * Show up to 3 brand names from the matching vendor_id products
 */
const PartnerHeader = ({
  brands
}) => {

  // Show 3 brands max
  const [hasMore, setHasMore] = React.useState();
  // Adjust for vertical-alignment short first brand name
  const [isShortBrand, setIsShortBrand] = React.useState();
  React.useEffect(() => {
    if (brands?.length) {
      setHasMore(brands.length > 3);
      // There are null brands in dev evidently
      if (brands[0] && brands[0].length < 7) {
        setIsShortBrand(true);
      }
    }
  }, [brands])

  return ( brands?.length
    ? <div className={styles.heroSection}>
        <div className={styles.heroWrap}>
          <div className={styles.header}>Products from:</div>
          <div className={`${styles.brands} ${isShortBrand ? styles.shortName : ''}`} data-brand-count={hasMore ? 4 : brands.length}>
            { brands.slice(0,3).map(brand =>
              brand
              ? <div key={brand} className={styles.brand}>{brand}</div>
              : null
            )}
            { hasMore &&
              <div className={styles.brand}>...and more!</div>
            }
          </div>
        </div>
      </div>
    : null
  )
}

PartnerHeader.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.string)
}

export default PartnerHeader;
