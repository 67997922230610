import * as React from 'react';

import { UserContext } from './UserProvider';
import { DiscountCreditAndFeeContext } from './DiscountCreditAndFeeProvider';
import { activeController } from '../util/util';
import { useAnalyticsStore } from '../../App';
import useCustomerOrders from '../orders/useCustomerOrders';
import useMinOrderForLocation from '../dispensary/useMinOrderForLocation';
import { fetchMetaData } from '../dispensary/dispensary-utils';

const US_CURRENCY_FORMAT = {
  style: 'currency',
  currency: 'USD',
};

const currencyFormat = (dollarValue) => {
  return typeof dollarValue === "number"
    ? dollarValue.toLocaleString('en-US', US_CURRENCY_FORMAT)
    : '0.00';
}

// Max for 2 numbers handling undefined
const greaterOf = (num1, num2) => {
  if (typeof num1 === "number" && typeof num2 === "number") {
    return Math.max(num1,num2);
  } else {
    return typeof num1 === "number"
      ? num1
      : 0;
  }
};

// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export const dollarDisplay = (cents, inDollars) => {
  if (cents && !isNaN(cents)) {
    const dollars = cents/100;
    return inDollars
      ? `$${Math.round(dollars)}`
      : currencyFormat(+(Math.round(dollars + "e+2")  + "e-2"));
  } else {
    return cents === 0
      ? '$0.00'
      : 'N/A';
  }
};

// Because discounts are applied to cannabis items first...
const calcTaxableTotalsAfterDiscounts = (itemTotal, nonCannabisTotal, discountTotal) => {

  // Determine the taxable amounts for cannabis/noncannabis
  let cannabisTotalCents = itemTotal - nonCannabisTotal;
  let nonCannabisTotalCents = nonCannabisTotal;
  // If the whole discount can be applied to cannabis products
  if (discountTotal <= cannabisTotalCents) {
    cannabisTotalCents-= discountTotal;
  // Else cannabis products first
  } else {
    nonCannabisTotalCents-= (discountTotal - cannabisTotalCents);
    cannabisTotalCents = 0;
  }
  return {
    taxableCannabisTotalCents: cannabisTotalCents,
    taxableNonCannabisTotalCents: nonCannabisTotalCents,
  };
};

// Used in taxes and fees tooltip only at this point --round UP
export const calcCannabisTaxCents = (itemTotal, nonCannabisItemTotal, discountTotal, cannabisTaxPct) => {
  const { taxableCannabisTotalCents } = calcTaxableTotalsAfterDiscounts(itemTotal, nonCannabisItemTotal, discountTotal);
  return Math.ceil((taxableCannabisTotalCents * cannabisTaxPct)/100);
}

// Use cents because: 30 + 7.23 = 37.230000000000004
export const calcTotalTaxCents = (itemTotal, nonCannabisItemTotal, discountTotal, salesTaxPct, nonCannabisSalesTaxPct, cannabisTaxPct) => {
  const { taxableCannabisTotalCents, taxableNonCannabisTotalCents } = calcTaxableTotalsAfterDiscounts(itemTotal, nonCannabisItemTotal, discountTotal);
  // Round once as the backend currently does
  return Math.ceil(
    (taxableNonCannabisTotalCents * nonCannabisSalesTaxPct)/100 +
    (taxableCannabisTotalCents * salesTaxPct)/100 +
    (taxableCannabisTotalCents * cannabisTaxPct)/100
  );
};

export const OrderPricingContext = React.createContext();

/**
 * This provider currently supplies context to checkout only
 */
const OrderPricingProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  // We need to refetch this on checkout view to stay current
  const { activeOrderCount } = useCustomerOrders(user);
  const { trackError } = useAnalyticsStore();
  const { minOrderCents } = useMinOrderForLocation();

  /*
   * We provide the greater of the standard
   * or discount code min order
   */
  const {
    discountCodeMinOrderCents,
  } = React.useContext(DiscountCreditAndFeeContext);

  const [cannabisTaxPct, setCannabisTaxPct] = React.useState();
  const [salesTaxPct, setSalesTaxPct] = React.useState();
  // Accessories etc.
  const [nonCannabisSalesTaxPct, setNonCannabisSalesTaxPct] = React.useState();

  // Always applied
  const [serviceFeeCents, setServiceFeeCents] = React.useState();
  // Possibly waived
  const [deliveryFeeCents, setDeliveryFeeCents] = React.useState();
  const [noDeliveryFeeThresholdCents, setNoDeliveryFeeThresholdCents] = React.useState();
  // Possibly waived
  const [smallOrderFeeCents, setSmallOrderFeeCents] = React.useState();
  const [smallOrderThresholdCents, setSmallOrderThresholdCents] = React.useState();

  const [loading, setLoading] = React.useState(true);

  /**
   * Provide the fee structure for order pricing calcs
   *
   * Fees and taxes are individually rounded and displayed as a single amount
   * e.g. feesAndTaxCents = feeCents + taxCents;
   */
  const pricingInfoCallback = React.useCallback((response) => {
    if (response.order_fees) {
      const { cannabis_tax_percent, sales_tax_percent,
              delivery_fee_cents, no_delivery_fee_threshold_cents, service_fee_cents,
              small_bag_fee_cents  /* small_order_threshold_cents */ } = response.order_fees;

      // TODO: Can we make this cleaner on the API side?
      const { sales_tax_percent:non_cannabis_sales_tax_pct }
        = response.order_fees.version_2?.taxes?.non_canabis || {};

      setSalesTaxPct(sales_tax_percent);
      setNonCannabisSalesTaxPct(non_cannabis_sales_tax_pct);
      setCannabisTaxPct(cannabis_tax_percent);
      setServiceFeeCents(service_fee_cents);
      setDeliveryFeeCents(delivery_fee_cents);
      setNoDeliveryFeeThresholdCents(no_delivery_fee_threshold_cents);
      setSmallOrderFeeCents(small_bag_fee_cents);
      // TODO FIXME: API still has 7500 as theshold, 9/25/24
      // setSmallOrderThresholdCents(small_order_threshold_cents);
      setSmallOrderThresholdCents(0);
    } else {
      trackError('error_setting_fee_structure');
    }
    setLoading(false);
  }, [trackError]);

  const controllerRef = React.useRef(new AbortController());

  // Fetch Meta Data
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (user && typeof salesTaxPct === "undefined") {
      setLoading(true);
      fetchMetaData(pricingInfoCallback, controller);
    }
    return () => controller.abort();
  }, [user, salesTaxPct, pricingInfoCallback]);

  return (
    <OrderPricingContext.Provider value={{
      loading,
      salesTaxPct,
      cannabisTaxPct,
      nonCannabisSalesTaxPct,
      serviceFeeCents,
      serviceFeeDisplay: dollarDisplay(serviceFeeCents),
      deliveryFeeCents,
      deliveryFeeDisplay: dollarDisplay(deliveryFeeCents),
      noDeliveryFeeThresholdCents,
      smallOrderFeeCents,
      smallOrderFeeDisplay: dollarDisplay(smallOrderFeeCents),
      smallOrderThresholdCents,
      smallOrderThresholdDisplay: dollarDisplay(smallOrderThresholdCents),
      minOrderOrDiscountMinCents: greaterOf(minOrderCents, discountCodeMinOrderCents),
      minOrderOrDiscountMinDisplay: dollarDisplay(greaterOf(minOrderCents, discountCodeMinOrderCents), true),
      activeOrderCount, // Free delivery messaging
      /* DISCOUNT CODES REMOVED */
      /* AEROPAY CREDIT REMOVED */
      /* WINDOW DISCOUNT REMOVED */
    }}>
      {children}
    </OrderPricingContext.Provider>
  );
};

export default OrderPricingProvider;
