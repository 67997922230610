import * as React from 'react';
import { DispensaryContext } from '../dispensary/DispensaryProvider';

import styles from '../banners/HeaderBanner.module.css';

// Shown only when we have the user's address and there's nothing carted.
const PriorityCalloutInHeader = () => {
  const { priorityETA } = React.useContext(DispensaryContext);
  return ( priorityETA
      ? <div className={`${styles.addressOverlay} ${styles.priorityOverlay}`}>
          <img alt="" src="/img/boltWithGlow.png" className={styles.priorityBoltIcon} />
          Priority Delivery by {priorityETA} Available!
        </div>
      : null
  );
};

export default PriorityCalloutInHeader;
