import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getShortenedPriceDisplay } from './product-utils';
import PropTypes from 'prop-types';

import styles from './DisplayPriceOnCard.module.css';

const MOBILE_BUNDLE_LABEL_MAX_CHARS = 11; // label wraps in mobile at 11 chars

/**
 * Handle display of sale price or bundle price next to original price
 * on product listing cards
 */
const DisplayPriceOnCard = ({
  cost_usa_cents,
  bundle_deal_data,
  discount_data,
}) => {

  // The Sale or Quantity Discount/Bundle Price
  const [discountedPrice, setDiscountedPrice] = React.useState();
  const [bundlePriceTeaser, setBundlepriceTeaser] = React.useState();
  const [longTeaser, setLongTeaser] = React.useState();

  React.useEffect(() => {
    const { cost_usa_cents_discounted } = discount_data || { cost_usa_cents_discounted: 0 };
    if (cost_usa_cents_discounted) {
      setDiscountedPrice(getShortenedPriceDisplay(cost_usa_cents_discounted));
    }
    const { values } = bundle_deal_data || {};
    if (values?.length) {
      const { unit_price, quantity } = values[0];
      const quantityPriceDisplay = getShortenedPriceDisplay(unit_price * quantity, true);
      const priceTeaser = `${quantity} for ${quantityPriceDisplay}!`;
      setBundlepriceTeaser(priceTeaser);
      setLongTeaser(priceTeaser.length > MOBILE_BUNDLE_LABEL_MAX_CHARS);
    }
  }, [bundle_deal_data, discount_data]);

  return (
    <span className={`${styles.wrapper} ${discountedPrice ? styles.salePriceWrapper : ''}`}>
      <span className={`${styles.amount} ${discountedPrice ? styles.onSale : ''}`}>
        {dollarDisplay(cost_usa_cents)}
      </span>
      <span className={`${styles.discountedPrice} ${longTeaser ? styles.longTeaser : ''}`}>
        { discountedPrice
          ? discountedPrice
          : bundlePriceTeaser
            ? <>{bundlePriceTeaser}</>
            : null
        }
      </span>
    </span>
  )
}

DisplayPriceOnCard.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  bundle_deal_data: PropTypes.object,
  discount_data: PropTypes.object,
}

export default  DisplayPriceOnCard;
