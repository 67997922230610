import * as React from 'react';

import { CartSuggestedProductsContext } from './CartSuggestedProductsProvider';
import { CarouselStyle } from '../common/SimpleCarousel';
import ProductCarousel from '../home/ProductCarousel';

import PropTypes from 'prop-types';

const CartSuggestedProductsCarousel = ({
  carouselTitle="Quick Adds",
}) => {

  const { suggestedProducts } = React.useContext(CartSuggestedProductsContext);

  return ( suggestedProducts
    ? <ProductCarousel
        carouselTitle={carouselTitle}
        /* caregoryCount is for the "See All" link which we don't use here */
        categoryCount={suggestedProducts.length}
        carouselProducts={suggestedProducts}
        /* Restore full product list during modal to modal viewing  */
        allRelatedProducts={suggestedProducts}
        carouselStyle={CarouselStyle.CART_SUGGESTED_PRODUCTS}
        trackingPrefix="cart"
        /* There are a few card image/sale label tweaks based on isSuggestedProduct */
        isSuggestedProduct />
    : null
  )
}

CartSuggestedProductsCarousel.propTypes = {
  carouselTitle: PropTypes.string,
};

export default CartSuggestedProductsCarousel;
