import * as React from 'react';

import config from '../../config';
import { getStoredValue,
         removeStoredValue,
         currentSurveyCompletedKey,
         hideHeaderReferralCalloutKey,
         hideHeaderSurveyCalloutKey } from '../util/storage-utils';
import { hasStoredCurrentSurveyKey } from '../surveyMonkey/survey-util';
import useCompletedSurveys from '../survey/useCompletedSurveys';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import SurveyCalloutInHeader from '../surveyMonkey/SurveyCalloutInHeader';
import ReferCalloutInHeader from '../refer/ReferCalloutInHeader';
import PriorityCalloutInHeader from '../priorityDelivery/PriorityCalloutInHeader';

const BANNER_TYPES = {
  SURVEY: 'survey',
  REFER: 'refer',
  PRIORITY: 'priority'
}

// In priority order
const PRIORITIZED_BANNERS = [
  { type: BANNER_TYPES.SURVEY, hideKey:hideHeaderSurveyCalloutKey },
  { type: BANNER_TYPES.REFER, hideKey:hideHeaderReferralCalloutKey },
  { type: BANNER_TYPES.PRIORITY},
];

// For dev reset
export const clearKeys = () => {
  removeStoredValue(hideHeaderSurveyCalloutKey);
  removeStoredValue(currentSurveyCompletedKey);
  removeStoredValue(hideHeaderReferralCalloutKey);
}

const DisplayHeaderBanner = () => {

  const { user, currentLocation, hasCompletedFirstOrder } = React.useContext(UserContext);
  const { cartItems } = React.useContext(CartItemsContext);
  const { priorityETA } = React.useContext(DispensaryContext);
  const { hasCompletedCurrentSurvey } = useCompletedSurveys(user);

  const [ enableBanners, setEnableBanners ] = React.useState();
  const [ bannerType, setBannerType] = React.useState();

  const isValidContextForBanner = React.useCallback((bannerData) => {
    // Handle user has closed banner first
    if (getStoredValue(bannerData.hideKey)) {
      return false;
    }
    switch (bannerData.type) {
      case BANNER_TYPES.SURVEY:
        // Checking both API and Storage to determine current survey completion
        return hasCompletedFirstOrder && !hasCompletedCurrentSurvey && !hasStoredCurrentSurveyKey();
      case BANNER_TYPES.REFER:
        return !user?.isAnonymous;
      case BANNER_TYPES.PRIORITY:
        return config.ENABLE_PRIORITY_HEADER_PROMO && !!priorityETA;
      default:
        return false;
    }
  }, [user, priorityETA, hasCompletedFirstOrder, hasCompletedCurrentSurvey])

  // We also show/hide the banner by page via HeaderBanner.module.css
  React.useEffect(() => {
    setEnableBanners(
      currentLocation &&  // Because banners cover the address prompt
      !cartItems.length
    );
  }, [currentLocation, cartItems])

  React.useEffect(() => {
    const firstAvailable = PRIORITIZED_BANNERS.find(
      bannerData => isValidContextForBanner(bannerData)
    )
    setBannerType(firstAvailable?.type);
  }, [user, isValidContextForBanner]);

  const renderBanner = (type) => {
    switch(type) {
      case BANNER_TYPES.SURVEY:
        return <SurveyCalloutInHeader />;
      case BANNER_TYPES.REFER:
        return <ReferCalloutInHeader />;
      case BANNER_TYPES.PRIORITY:
        return <PriorityCalloutInHeader />;
      default:
        return null;
    }
  }

  return (
    <>
      { enableBanners && bannerType
        ? renderBanner(bannerType)
        : null
      }
      {/*
        <div style={{position:'absolute'}} onClick={clearKeys}>clear</div>
      */}
    </>
  )
}

export default DisplayHeaderBanner;
