import * as React from 'react';
import closeX from '../../assets/closeX.png';

/**
 * Just a close modal X icon, styled.
 * Required styles:
 *   .closeWrapper
 *   .closeIcon
 */
const CloseModalIcon = ({
  classes,
  height=24,
  closeFn
}) => (
  <span className={classes.closeWrapper} onClick={closeFn}>
    <img alt="Close Modal" className={classes.closeIcon} src={closeX} height={height} />
  </span>
)

export default CloseModalIcon;
