import { useCallback, useContext } from "react"

import { trackEvent, trackError } from "../analytics/tracking";
import { UserContext } from "../providers/UserProvider";
import usePost from "../util/usePost";
import {
  klaviyoCheckoutStartEndpoint,
  klaviyoProductActionEndpoint
} from "../../constants/api-endpoints";

// Start checkout
// https://ziprun-qa2.uk.r.appspot.com/api/v1/docs#tag/customer/paths/~1customer~1events~1started_checkout/post

// Product action
// https://ziprun-qa2.uk.r.appspot.com/api/v1/docs#tag/customer/paths/~1customer~1events~1product_action/post

export const LOGGING_DETAILS = {
  CHECKOUT_START: {
    endpoint: klaviyoCheckoutStartEndpoint,
    label: 'checkout_start'
  },
  PRODUCT_VIEW: {
    endpoint: klaviyoProductActionEndpoint,
    label: 'product_view'
  },
  PRODUCT_CARTED: {
    endpoint: klaviyoProductActionEndpoint,
    label: 'product_carted'
  }
}

const useKlaviyoLogging = (details) => {

  const { user } = useContext(UserContext);

  const trackSuccess = useCallback(() => {
    trackEvent(`klaviyo_${details.label}_post_success`);
  }, [details])

  const trackFailure = useCallback(() => {
    trackError(`klaviyo_${details.label}_post_error`);
  }, [details])

  const { postData } = usePost(
    user,
    details?.endpoint,
    trackSuccess,
    trackFailure,
  );

  const logKlaviyoEvent = useCallback((data) => {
    // alert(JSON.stringify(data));
    postData(data);
  }, [postData])

  return {
    logKlaviyoEvent,
  }
}

export default useKlaviyoLogging;
