import * as React from "react";

import PropTypes from 'prop-types';

const DisplayCartedDealProducts = ({
  superDealProducts,
}) => {

  return ( superDealProducts?.length
    ? <table style={{marginTop:6, width:'100%'}}>
        <tbody>
          <tr>
            <th style={{textAlign:'left'}}>Super Deal:</th>
          </tr>
          { superDealProducts.map(product => (
            <tr key={product.id}>
              <td>{product.display_info.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    : null
  );
};

DisplayCartedDealProducts.propTypes = {
  superDealProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_info: PropTypes.object.isRequired,
  })),
}

export default DisplayCartedDealProducts;
