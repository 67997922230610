import * as React from 'react';

import SubCategoryAndWeight from './SubCategoryAndWeight';
import CbdIcon from '../../assets/cbdIcon.svg';
import HybridIcon from '../../assets/hybridIcon.svg';
import IndicaIcon from '../../assets/indicaIcon.svg';
import SativaIcon from '../../assets/sativaIcon.svg';

import PropTypes from 'prop-types';

import styles from './LabResults.module.css';

// Known Leaf Logix values
export const unitSymbolMap = new Map([
  ['Percentage','%'],
  ['Milligrams', 'mg'],
  ['MilligramsPerGram', 'mg/g']
]);

export const TypeIcons = {
  CBD: CbdIcon,
  Hybrid: HybridIcon,
  Indica: IndicaIcon,
  Sativa: SativaIcon
};

export const TypeColors = {
  CBD: '#8239bf',       // 6.38 to 1 purple
  Hybrid: '#32A280',    // 3.17 to 1 green
  Indica: 'var(--zrPrimaryDark)', // 4.6 to 1 blue
  Sativa: '#f06100',    // 3.27 to 1 orange
  DEFAULT: '#000'       // black
}

export const TypeBackgroundColors = {
  CBD: '#ddc6f1',      // purple
  Hybrid: '#32A28044', // green-blue
  Indica: '#d5e9fd',   // blue
  Sativa: '#ff67005f', // orange
  DEFAULT: '#f0f0f0'   // gray
}

/**
 * Spec for what to show on cards is here:
 * https://app.shortcut.com/zip-run/story/4553/web-app-product-card-testing-information-cards-detail
 */
// On  the modal we only show certain lab results
const CANNABINOID_WHITE_LIST = ['TAC', 'THC', 'THCV', 'CBC', 'CBD', 'CBDV', 'CBG', 'CBN'];
const TOTAL_TERPS_DISPLAY_MIN_PCT = 2;

const LabResults = ({
  productId,
  lab_results,
  cannabisType,
  category,
  subCategory,
  grams
}) => {

  const [typeIcon, setTypeIcon] = React.useState();
  React.useEffect(() => {
    if (TypeIcons[cannabisType]) {
      setTypeIcon(TypeIcons[cannabisType])
    }
  }, [cannabisType])

  const [testResults, setTestResults] = React.useState();
  const [totalTerps, setTotalTerps] = React.useState();

  React.useEffect(() => {
    const results = [];
    // Using white list for desired display order
    CANNABINOID_WHITE_LIST.forEach(enabledTest => {
      const result = lab_results?.find(item => item.labTest === enabledTest) || undefined;
      if (result && result.value > 0) {
        results.push(result);
      }
    })
    // Log Total Terpines % data
    // lab_results?.forEach(result => {
    //   console.log(`Test: ${result.labTest}, value: ${result.value}`);
    // })
    setTestResults(results);

    // Only displaying 'total terpenes %' currently
    const totalTerpenes = lab_results.find(result => result.labTest === 'TotalTerpenes');
    if (totalTerpenes && totalTerpenes.value >= TOTAL_TERPS_DISPLAY_MIN_PCT) {
      setTotalTerps(totalTerpenes);
    }
  },[lab_results])

  return (
    <>
      <div className={styles.strainWrap}>
        <span>{cannabisType}</span>
        { typeIcon &&
          <img alt={cannabisType} className={styles.strainIcon} src={typeIcon} />
        }
      </div>

      <SubCategoryAndWeight
        category={category}
        subCategory={subCategory}
        grams={grams} />

      { testResults?.length > 0
        ?
          <>
            <ol className={styles.compounds}>
              { testResults.map(result => {
                  return (
                    <li key={`${productId}-${result.labTest}`}>
                      <div className={styles.compound}>
                        {result.value}{unitSymbolMap.get(result.labResultUnit)}
                        <div className={styles.compoundLabel}>
                          {result.labTest}*
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ol>
            { totalTerps
              ? <div className={styles.totalTerps}>
                  TOTAL TERPENES:
                  <span className={styles.totalTerpsValue}>
                    {totalTerps.value}{unitSymbolMap.get(totalTerps.labResultUnit)}*
                  </span>
                </div>
              : null
            }
            <div className={styles.disclaimor}>*Amounts are averages, individual items may vary.</div>
          </>
        : null
      }
    </>
  )
}

LabResults.propTypes = {
  productId: PropTypes.string.isRequired,
  lab_results: PropTypes.array,
  cannabisType: PropTypes.string,
  category: PropTypes.object,
  subCategory: PropTypes.object,
  grams: PropTypes.number
}

export default  LabResults;
