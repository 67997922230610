import * as React from 'react';

import Header, { HeaderSize } from '../styleguide/Header';
import { CarouselStyle } from '../common/SimpleCarousel';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

/**
 * Display carousel title: text or component
 */
const ProductCarouselHeader = ({
  carouselTitle,
  carouselTitleComponent,
  carouselSubTitle,
  carouselStyle=CarouselStyle.PRODUCT_CARDS,
}) => {

  return ( carouselTitle
    ? <Header size={HeaderSize.Medium_Static} withStyle={{ margin:0 }}>
        { [CarouselStyle.SUGGESTED_PRODUCTS,
           CarouselStyle.CART_SUGGESTED_PRODUCTS].includes(carouselStyle)
          ? <span className={styles.suggestedHeader}>
              {carouselTitleComponent || carouselTitle}
            </span>
          : <span className={styles.categoryName}>
              {carouselTitleComponent || carouselTitle}
            </span>
        }
        { carouselSubTitle &&
          <div className={styles.subTitle}>
            {carouselSubTitle}
          </div>
        }
      </Header>
    : null
  );
}

ProductCarouselHeader.propTypes = {
  carouselTitle: PropTypes.string.isRequired,
  carouselTitleComponent: PropTypes.object,
  carouselSubTitle: PropTypes.string,
  carouselStyle: PropTypes.string,
};

export default ProductCarouselHeader;
