import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { navigateTopWithState } from '../routing/router-utils';
import { hideHeaderReferralCalloutKey, storeValue } from '../util/storage-utils';
import { CREDIT_DETAILS } from './GetReferCreditDetails';

import styles from '../banners/HeaderBanner.module.css';

import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const ReferCalloutInHeader = () => {

  const referClick = React.useCallback(() => {
    trackEvent('refer_hdr_callout_clk');
    navigateTopWithState('/refer', {
      returnToPath: `${window.location.pathname}`
    });
  }, [])

  const [hideBanner, setHideBanner] = React.useState();
  const closeBanner = () => {
    setHideBanner(true);
    trackEvent('refer_hdr_callout_hide');
    storeValue(hideHeaderReferralCalloutKey, true);
  }

  return ( hideBanner
      ? null
      : <div className={styles.addressOverlay}>
          Give ${CREDIT_DETAILS.total},Get ${CREDIT_DETAILS.total}:
          <span onClick={referClick} className={styles.referLink}>Refer your Friends!</span>
          <span className={styles.overlayClose} onClick={closeBanner}>
            <HighlightOffRoundedIcon style={{fontSize:'25px'}} />
          </span>
        </div>
  );
};

export default ReferCalloutInHeader;
