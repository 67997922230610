import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import ProductCarousel, { MAX_CAROUSEL_PRODUCTS } from './ProductCarousel';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

const thcValue = (product) => {
  const { lab_results=[] } = product.display_info || {};
  const thcTest = lab_results.find(result => result.labTest === 'THC');
  return thcTest?.value || 0;
}

const THCHeaderWithSort = ({sortHigh, sortFn}) => (
  <div className={styles.headerGrid}>
  <span>Flower By THC%</span>
  { sortHigh
    ? <span className={styles.headerLink} onClick={() => sortFn(false)}>Sort Lowest First</span>
    : <span className={styles.headerLink} onClick={() => sortFn(true)}>Sort Highest First</span>
  }
  </div>
)

const FlowerSortedByTHC = () => {

  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [sortedProducts, setSortedProducts] = React.useState();
  const [categoryProducts, setCategoryProducts] = React.useState();
  const [sortHigh, setSortHigh] = React.useState(true);

  // Sort products by THC%
  React.useEffect(() => {
    const products = productsByCategory.get('Flower');
    if (products?.length) {
      // Don't mutate the provider order
      const productsClone = [ ...products];
      productsClone.sort((a,b) => sortHigh
        ? thcValue(b) - thcValue(a)
        : thcValue(a) - thcValue(b))
      setSortedProducts(productsClone.slice(0,MAX_CAROUSEL_PRODUCTS));
      setCategoryProducts(products);
    }
  }, [productsByCategory, sortHigh])

  return ( sortedProducts
    ? sortedProducts.length === 0
      ? null
      : <ProductCarousel
          carouselTitle='Flower'
          carouselTitleComponent={(
            <THCHeaderWithSort sortHigh={sortHigh} sortFn={setSortHigh} />
          )}
          categoryCount={categoryProducts.length}
          carouselProducts={sortedProducts}
          withMoreCard
          trackingPrefix="by_thc"
          seeAllClick={() => {
            navigateTop(`/products/Flower`);
          }} />
    : <Spinner />
  )
}

FlowerSortedByTHC.propTypes = {
  headerPrefix: PropTypes.string
}

export default FlowerSortedByTHC;
