import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { SURVEY_TRACKING_LABEL } from './SurveyEmbed';
import MuiModal from '../modal/MuiModal';
import SurveyEmbed from './SurveyEmbed';
import PropTypes from 'prop-types';

import styles from './SurveyMonkey.module.css';

const SurveyMonkeyModal = ({ctaText='Required!', linkText}) => {

  const [showSurveyModal, setShowSurveyModal] = React.useState();

  const openModal = () => {
    setShowSurveyModal(true);
    trackEvent(`${SURVEY_TRACKING_LABEL}_open_modal_clk`);
  }

  const cancelModal = () => {
    setShowSurveyModal(false);
    trackEvent(`${SURVEY_TRACKING_LABEL}_cancel_modal_clk`);
  }

  const closeModal = () => {
    setShowSurveyModal(false);
    trackEvent(`${SURVEY_TRACKING_LABEL}_completed_close_clk`);
  }

  return (
      <>
        <div onClick={openModal}>
          <span>{ctaText}</span>
          { linkText &&
            <span className={styles.linkStyle}>
              {linkText}
            </span>
          }
        </div>
        <MuiModal
          open={showSurveyModal}
          closeFn={cancelModal}>
            <SurveyEmbed onComplete={closeModal} />
        </MuiModal>
      </>
  )
}

SurveyMonkeyModal.propTypes = {
  ctaText: PropTypes.string.isRequired,
  linkText: PropTypes.string,
}

export default SurveyMonkeyModal;
