import * as React from 'react';

import { devLog } from '../util/util';
import config from '../../config';
import { useAnalyticsStore } from '../../App';
import { FilteredProductsContext, subCategoryAll } from './FilteredProductsProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { navigateTop, getScrollPixels } from '../routing/router-utils';
import Spinner from '../common/Spinner';
import Header, { HeaderSize } from '../styleguide/Header';
import ServiceMessages from '../dispensary/ServiceMessages';
import ProductFilterCarousel from '../dispensary/ProductFilterCarousel';
import ProductFilters from '../productFilters/ProductFilters';
import NoMatchingProducts from '../productFilters/NoMatchingProducts';
import ActiveFilterPills from '../productFilters/ActiveFilterPills';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import AddedToCartCheck from './AddedToCartCheck';
import ProductListing from '../dispensary/ProductListing';
import FilterIcon from '../../assets/filterIcon2.png';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle';
import { PropTypes } from 'prop-types';

import styles from '../dispensary/Dispensary.module.css';

const Products = ({category, subCategoryName}) => {

  const { trackEvent } = useAnalyticsStore();
  const { filteredProducts, categoryName, setCategory, subCategory, subCategories, setFilters } = React.useContext(FilteredProductsContext);
  // mobile filter modal
  const { displayModal } = React.useContext(ModalContext);

  // Handle category on initial render
  React.useEffect(() => {
    if (category && category !== categoryName) {
      setCategory(category);
      devLog('SETTING PRODUCT CATEGORY');
    }
  }, [category, categoryName, setCategory]);

  // Handle sub-category on initial render
  React.useEffect(() => {
    if (subCategories.length) {
      if(subCategoryName && subCategoryName !== subCategory.display_name) {
        const subCat = subCategories.find(sub => sub.display_name === subCategoryName);
        if (subCat) {
          setFilters({ subCategory: subCat });
          devLog(`SETTING PRODUCT SUB_CATEGORY ${subCat.display_name}`);
        }
      // Reset sub-category to all when url param is removed
      } else if (!subCategoryName && subCategory.name !== subCategoryAll.name) {
        setFilters({ subCategory: subCategoryAll });
        devLog(`SETTING PRODUCT SUB_CATEGORY ${subCategoryAll.display_name}`);
      }
    }
  }, [subCategoryName, subCategory, subCategories, setFilters]);

  // Show checkmark mini-modal on add-to-cart
  const [showAddedCheck, setShowAddedCheck] = React.useState();

  // Dispensary info from Providers
  const dispensaryId = config.ZYP_RUN_DISPENSARY_ID;
  const { addItem } = React.useContext(CartItemsContext);

  // Navigate on category change
  const updateProductCategory = React.useCallback((category) => {
    trackEvent(`products_category_${category.name}_click`);
    const scrollYTo = getScrollPixels(scrollToTarget);
    // Race condition? navigation alone doesn't handle category change correctly
    setCategory(category.display_name);
    navigateTop(`/products/${category.display_name}`, { scrollYTo });
  }, [setCategory, trackEvent]);

  // Navigate on sub-category change
  const updateProductSubCategory = React.useCallback((subCategory) => {
    trackEvent(`products_sub_category_${subCategory.name}_click`);
    if (subCategory.name === subCategoryAll.name) {
      navigateTop(`/products/${categoryName}`);
    } else {
      navigateTop(`/products/${categoryName}/${subCategory.display_name}`);
    }
  }, [trackEvent, categoryName]);

  const handleAddToCart = (id, item, quantity) => {
    setShowAddedCheck(true);
    addItem(id, item, quantity);
  }

  const scrollToTarget = React.useRef();

  return (
    <BodyWrapper LayoutType={LayoutTypes.FullBleed}>
      { dispensaryId && categoryName
        ? <>
            <PageTitle title= {categoryName} />
            <ServiceMessages />
            <ProductFilterCarousel
              currentCategory={categoryName}
              handleClick={updateProductCategory} />

            <div data-is-scroll-target="true" ref={scrollToTarget} style={{height:0}} ></div>
            <div className={styles.productGridWithFilters}>
              <div className={styles.headerWithFilters}>
                <Header size={HeaderSize.Large} text={categoryName} />
                <div className={styles.hdrFilterWrap} onClick={() => {
                  displayModal(ModalType.PRODUCT_FILTER, {
                    onSubCategoryClick: updateProductSubCategory,
                    trackContext: 'prod_filter'
                  })
                }}>
                  <img className={styles.filterIcon} alt="filter" src={FilterIcon} />
                  Filter
                </div>
              </div>

              <div className={styles.filterCol}>
                <ProductFilters
                  onSubCategoryClick={updateProductSubCategory}
                  startOpen />
                {/* Pills show elsewhere when there's no matches! */}
                { filteredProducts.length > 0 &&
                  <ActiveFilterPills inMobileNav />
                }
              </div>

              <div className={styles.productGrid}>
                { filteredProducts?.length > 0
                  ? <ProductListing
                      dispensaryId={dispensaryId}
                      filteredProducts={filteredProducts}
                      handleClick={handleAddToCart} />
                  : filteredProducts?.length === 0
                    ? <NoMatchingProducts
                        categoryName={categoryName}
                        subCategory={subCategory} />
                    : <Spinner />
                }
              </div>
            </div>
          </>
         : <Spinner />
      }

      { showAddedCheck &&
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false) }} />
      }
    </BodyWrapper>
  )
}

Products.propTypes = {
  category: PropTypes.string /* via router */
}

export default Products;
