import * as React from 'react';

import config from '../../config';
import { customerCartEndpoint } from '../../constants/api-endpoints';
import { activeController, devLog } from '../util/util';
import useFetch from '../util/useFetch';
import useCustomerDetails from '../account/useCustomerDetails';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { getSaveCartItemDetails, saveCartItems, clearSavedCartItems } from './order-utils';

/**
 * Save/Restore cart items via the API for use with restoring cart
 * in new sessions and for triggering abandon cart emails
 */
const useSaveCartItems = (user) => {

  const {
    zypRunUser,
    emailOptIn: email_opted_in,
    textOptIn: sms_text_opted_in
  } = useCustomerDetails(user);

  // These items are restored to the cart but then cleared from state
  const [savedCartItems, setSavedCartItems] = React.useState([]);
  // These items are for abandon cart traffic: home page messaging
  const [availableSavedItems, setAvailableSavedItems] = React.useState();
  const [unavailableSavedItems, setUnavailableSavedItems] = React.useState();

  const { allProducts, getCachedProductBySku } = React.useContext(ProductPreviewContext);
  const controllerRef = React.useRef(new AbortController());

  const { data:savedCart } = useFetch(user, customerCartEndpoint, false);

  // Only currently available cart products can be restored to cart
  React.useEffect(() => {
    if (user && !user.isAnonymous && allProducts && savedCart?.items?.length) {
      // Partition into available / unavailable items
      const available = [];
      const unavailable = [];
      savedCart.items.forEach(savedItem => {
        // Oddly it's .sku from API instead of .dutchie_sku
        const itemClone = getCachedProductBySku(savedItem.sku);
        if (itemClone) {
          itemClone.quantity = savedItem.quantity;
          available.push(itemClone);
        } else {
          unavailable.push(savedItem);
        }
      })
      devLog(`CART RESTORE: Restoring ${available.length} items`);
      setSavedCartItems(available);
      // display only
      setAvailableSavedItems(available);
      setUnavailableSavedItems(unavailable);
    } else if (savedCart?.items?.length === 0) {
      setSavedCartItems([]);
      setAvailableSavedItems([]);
      setUnavailableSavedItems([]);
    }
  }, [user, allProducts, savedCart, getCachedProductBySku]);

  const saveCart = React.useCallback((cartItems) => {
    // Cart Save is for registered users only
    if (!user || user.isAnonymous) {
      return;
    }
    const controller = activeController(controllerRef);
    if (cartItems.length === 0) {
      // clear the user's saved cart items
      devLog('CART CLEAR: Cart is empty');
      clearSavedCartItems(controller);
      setSavedCartItems([]);
    } else if (cartItems?.length > 0 && zypRunUser) {
      const { id:customer_id, name, email } = zypRunUser || {};
      // Save the user's cart items
      const cartInfo = {
        customer_id,
        name,
        email,
        marketing_communications: {
          email_opted_in,
          sms_text_opted_in
        },
        order_type: 'delivery',
        dispensary_id: config.ZYP_RUN_DISPENSARY_ID,
        items: getSaveCartItemDetails(cartItems)
      };
      devLog(`CART SAVE: Cart has ${cartItems.length} items`);
      saveCartItems(cartInfo, controller);
    }
    return () => controller.abort();
  }, [user, zypRunUser, email_opted_in, sms_text_opted_in]);

  return {
    savedCartItems,
    availableSavedItems,   // Cart reminder traffic
    unavailableSavedItems, // Cart reminder traffic
    setSavedCartItems,
    saveCart
  }
}

export default useSaveCartItems;
