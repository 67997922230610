import * as React from 'react';

import { activeController } from './util';

const usePost = (user, url, onSuccess, onFailure) => {

  const controllerRef = React.useRef(new AbortController());

  const postData = React.useCallback((data) => {

    // alert(JSON.stringify(data));
    const controller = activeController(controllerRef);

    if (user && url) {
      user.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': idToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
          signal: controller.signal
        }).then(response => {
            response.status === 201
            ? onSuccess()
            : response.json().then(data => {
                const { error='postData error' } = data;
                onFailure({message:error});
              })
        }).catch(error => {
          onFailure(error);
        });
      });
    }
  }, [user, url, onFailure, onSuccess])

  return {
    postData,
  }
}

export default usePost;
