import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import DisplayQRCode from '../common/DisplayQRCode';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Header, {HeaderSize} from '../styleguide/Header';

const useStyles = makeStyles(theme => ({
  wrapper: {
    boxSizing: 'border-box',
    width: 450,
    maxWidth: '90vw',
    padding: '1rem'
  },
  body: {
   padding: '1rem',
   fontSize: '1.125rem'
  },
  disclaimer: {
    marginTop: 12,
    fontSize: '.75rem',
    fontStyle: 'italic',
    textAlign: 'center'
  }
}))

const QRCodeModal = ({ modalProps }) => {

  const classes = useStyles();
  const { codeUrl, trackContext } = modalProps;

  return (
    <DisplayModal title="Share QR Code"
                  trackContext={trackContext}
                  isShrinkToFit >
      <div className={classes.wrapper}>
        { codeUrl
          ? <div>
              <DisplayQRCode url={codeUrl} />
              <div className={classes.body}>
                <Header
                  size={HeaderSize.Large}
                  isCentered
                  text="Scan Your Code"
                  withStyle={{fontStyle:'italic'}}/>
                <div>
                  Have your friends use their phone's camera to scan the QR Code above.
                  <div className={classes.disclaimer}>
                    The QR Code above contains your unique Zyp Run referral link.
                  </div>
                </div>
              </div>
            </div>
          : null
        }
      </div>
    </DisplayModal>
  );
};

QRCodeModal.propTypes = {
  modalProps: PropTypes.object.isRequired
}

export default QRCodeModal;
