import { useEffect, useState } from "react";

export const EVENT_TYPE = {
  VIEW: 'viewed-product',
  CART: 'added-to-cart',
}

const buildSubCategories = (product={}) => {
  const {
    display_info,
    is_pre_ground,
    is_small_buds,
  } = product;
  const subCats = [];
  is_pre_ground && subCats.push('Pre-ground Flower');
  is_small_buds && subCats.push('Small Buds Flower');
  if (display_info.sub_category) {
    subCats.push(display_info.sub_category.name);
  }
  return subCats;
}

export const buildSingleProductData = (product, event_name) => {
  let data;
  if (product && event_name) {
    const {
      id,
      dutchie_sku:sku,
      is_discount_code_restricted:is_super_deal,
      bundle_deal_data
    } = product;

    const price_cents = product.display_info.cost_usa_cents;
    const sale_price_cents = product.discount_data?.cost_usa_cents_discounted;
    data = {
      id,
      sku,
      category: product.display_info.category.name,
      subcategories: buildSubCategories(product),
      is_super_deal,
      can_be_bundled: Boolean(bundle_deal_data),
      is_on_sale: Boolean(sale_price_cents),
      price_cents: sale_price_cents || price_cents,
      event_name,
    };
  }
  return data;
}

const useSingleProductMetaData = (product, event_name) => {

  const [metaData, setMetaData] = useState();

  useEffect(() => {
    if (product && event_name) {
      const data = buildSingleProductData(product, event_name);
      setMetaData(data);
    }
  }, [product, event_name])

  return {
    metaData,
  }
}

export default useSingleProductMetaData;
