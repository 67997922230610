import React from 'react';

import { ModalContext, ModalType } from '../modal/ModalProvider';
import useBrandPartnerData from './useBrandPartnerData';
import { optimizedImage } from '../products/CroppedProductImage';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';

import styles from './BrandHero.module.css';

const BrandHero = ({
  brandName
}) => {

  const { data={}} = useBrandPartnerData(brandName);
  const { displayModal } = React.useContext(ModalContext);
  const { name, hero, heroMobile, logo, logoTop } = data;

  const iconStyle={
    fontSize: '20px',
    verticalAlign: -5,
    marginLeft: 4
  }

  return ( name
    ? <div className={styles.heroSection}>
        <div className={styles.heroWrap}>
          { hero &&
            <img alt={name} src={optimizedImage(hero, 1300)} className={styles.brandHero} />
          }
          { heroMobile &&
            <img alt={name} src={optimizedImage(heroMobile, 800)} className={`${styles.brandHero} ${styles.brandHeroMobile}`} />
          }
          { logo &&
            <img alt={name} src={optimizedImage(logo)} className={`${styles.brandLogo} ${logoTop ? styles.logoTop : ''}`} />
          }
          <div className={styles.aboutLink}
               onClick={() => {
                 displayModal(ModalType.PARTNER_INFO,{
                   trackingContext: 'partner_info',
                   partnerName: brandName
                 })
               }}>
            About {name}
            <InfoIcon style={iconStyle} />
          </div>
        </div>
      </div>
    : null
  );
}

BrandHero.propTypes = {
  brandName: PropTypes.string
};

export default BrandHero;
