import * as React from 'react';

import { devLog } from '../util/util';
import { SURVEY_TRACKING_LABEL } from '../surveyMonkey/SurveyEmbed';
import { surveyCompletionEndpoint } from '../../constants/api-endpoints';
import useFetch from '../util/useFetch';

/**
 * Fetch the survey keys for user's completed surveys
 */
export const useCompletedSurveys = (user) => {
  const {
    data,
    ...rest
  } = useFetch(
    user,
    `${surveyCompletionEndpoint}?customer_id=${user?.uid}`,
    false /* allowAnonymous */,
    undefined, /* urlSuffix */
    !user?.uid /* paused */
  );

  const [completedSurveys, setCompletedSurveys] = React.useState();
  // Based on current survey tracking constant
  const [hasCompletedCurrentSurvey, setHasCompletedCurrentSurvey] = React.useState();

  React.useEffect(() => {
    const { surveys } = data || {};
    let currentSurvey;
    if (user?.uid && typeof(surveys?.length) === 'number') {
      setCompletedSurveys(surveys);
      currentSurvey = surveys.find(survey => survey.survey_id === SURVEY_TRACKING_LABEL);
      setHasCompletedCurrentSurvey(!!currentSurvey);
    } else {
      setCompletedSurveys(undefined);
      setHasCompletedCurrentSurvey(undefined);
    }
    devLog(`useCompletedSurveys: Completed:${data?.length} Current: ${currentSurvey||false}`);
  }, [user, data]);

  return {
    completedSurveys,
    hasCompletedCurrentSurvey,
    ...rest,
  };
}

export default useCompletedSurveys;
