import React from 'react';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import StoreHoursToolTip from '../dispensary/StoreHoursToolTip';
import { formattedHours } from '../dispensary/StoreInfo';
import { daysOfWeek } from '../util/date-utils';
import { makeStyles, capitalize } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'left',
    font: 'var(--zrFont)',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'right'
    }
  },

  deliveryDetails: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem',
      padding: '12px 8px 0 0',
    }
  },
  deliveryLabel: {
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit',
    color: 'var(--zrPrimaryDark)',
    paddingRight: 4
  },

  hours: {
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit'
  },

  toolTipWrap: {
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      marginTop: 4,
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 100%',
      textAlign: 'right',
      marginLeft: 0,
    },
  },
}));

const DeliveryServiceDetails = () => {

  const { dispensary } = React.useContext(DispensaryContext);
  const { hours_of_operation } = dispensary || {};

  const [displayData, setDisplayData] = React.useState();
  React.useEffect(() => {
    if (hours_of_operation && !displayData) {
      const currentDay = new Date().getDay();
      const dayOfWeek = daysOfWeek[currentDay];
      const [ data ] = hours_of_operation.filter(hours => hours.weekday === dayOfWeek);
      setDisplayData(data);
    }
  }, [hours_of_operation, displayData]);

  const classes = useStyles();

  return ( displayData
    ? <div className={classes.wrapper}>
        <span className={classes.deliveryDetails}>
          <span className={classes.deliveryLabel}>
            {capitalize(displayData.weekday)} Delivery:
          </span>
          {' '}
          <span className={classes.hours}>
            { formattedHours(displayData) }
          </span>
          {' '}
        </span>
        <span className={classes.toolTipWrap}>
          <StoreHoursToolTip hours_of_operation={hours_of_operation} />
        </span>
      </div>
    : null
  );
}

export default DeliveryServiceDetails;
